import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue')
  },
    {
      path: '/marriage',
      name: 'marriage',
      component: () => import('@/views/marriage/index.vue')
    },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/register/index.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/index.vue'),
    children: [
      {
        path: '/distributor',
        name: 'distributor',
        component: () => import('@/views/home/views/distributor/index.vue')
      },
      {
        path: '/producer',
        name: 'producer',
        component: () => import('@/views/home/views/producer/index.vue')
      },
      {
        path: '/retailer',
        name: 'retailer',
        component: () => import('@/views/home/views/retailer/index.vue')
      },
      {
        path: '/trace',
        name: 'trace',
        component: () => import('@/views/home/views/trace/index.vue')
      },
      {
        path: '/user/center',
        name: 'user-center',
        component: () => import('@/views/home/views/user-center/index.vue')
      },
      {
        path: '/block',
        name: 'user-center',
        component: () => import('@/views/home/views/block/index.vue')
      },
    ]
  },
]

const router = new VueRouter({
  routes
})

export default router
